import React from 'react';
import styled from 'styled-components';


const ITCDiv = styled.div`
  display: flex;  
`;

const ITCH4 = styled.h4`
  text-align: left;
`;

export default function ImageTitleCopy({image, title, copy, desc}) {
  return (
    <ITCDiv>
      <img style={{padding: 20}} alt={title} src={image} height="100" width="100"/>
      <div>
        <ITCH4>{title}</ITCH4>
        <p>{copy}</p>
      </div>
    </ITCDiv>
  )
}