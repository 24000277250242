import React from 'react';
import styled from 'styled-components';

const StaticCardDiv = styled.div`
    background-color: white;
    box-shadow: 0 2px 4px 0 hsla(198, 45%, 10%, .12);
    border-radius: 10px;
    padding: 24px;
    flex: 1 0 30%;
    margin: 8px;
  `;

export default function StaticCard(props) {
  return (
    <StaticCardDiv>
      { props.children }
    </StaticCardDiv>
  )
}