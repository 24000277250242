import {graphql, useStaticQuery} from 'gatsby';

const servicesPageQuery = graphql`
  query {
    allContentfulServicesPage(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        whatWeDo {
          title
          image {
            file {
              url
            }
          }
          description {
            description
          }
        }
        projectTimeline {
          title
          description {
            description
          }
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default function useServicePage() {
  const servicePageNode = useStaticQuery(servicesPageQuery).allContentfulServicesPage.nodes[0];
  return servicePageNode;
}