import React from 'react';

import ImageTitleCopy from "../components/ImageTitleCopy";

import useServicePage from '../hooks/useServicesPage';

import {ButtonLink} from "../components/Button";
import StaticCard from "../components/containers/StaticCard";
import Layout from '../components/Layout';
import SectionBlock from '../components/containers/SectionBlock';
import GeometricBackgroundWrapper from '../components/containers/GeometricBackgroundWrapper';
import Theme from '../styles/Theme';

import styled from 'styled-components';
import Timeline from '../components/Timeline';

const WWDDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ServiceSectionBlock = styled(SectionBlock)`
  text-align: center;
`;

const FootH2 = styled.h2`
  text-align: center;
`;


export default function Services({location}) {
  const {whatWeDo, projectTimeline} = useServicePage();
  const timelineItems = projectTimeline.map((node) => (
    {
      title: node.title,
      content: node.description.description,
      icon: <img alt={node.title} src={node.icon.file.url} style={{color: 'white', marginLeft: '12.5%', marginTop: '12.5%'}} height={'75%'} width={'75%'} />
    }
  ))

  return (
    <Layout location={location}>
      <SectionBlock>
        <div>
          <h2>What We Do</h2>
          <br/>
          <WWDDiv>

            {
              whatWeDo.map((node) =>
              <StaticCard>
                <ImageTitleCopy title={node.title} image={node.image.file.url} copy={node.description.description}/>
              </StaticCard>
              )
            }
          </WWDDiv>   
        </div>
      </SectionBlock>

      <SectionBlock>
        <div>
          <h2>Our Approach</h2>
          <br></br>
          <Timeline items={timelineItems} />
        </div>
      </SectionBlock>

      <GeometricBackgroundWrapper
        size={'550px'}
        top={'-10vh'}
        color={Theme.colors.lightBlue025}
        left={'20vw'}
        shape={'circle'}
      >
        <ServiceSectionBlock>
          <FootH2>Interested? Start your consulting journey with us</FootH2>
          <br/>
          <ButtonLink to={'/contact/'}>
            Contact Us
          </ButtonLink>
        </ServiceSectionBlock>
      </GeometricBackgroundWrapper>
    </Layout>
  )
}
